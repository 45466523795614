import axios from 'axios';
import { useDispatch } from 'react-redux';

import { Creators as RequestActions } from '~/store/ducks/request';

export const axiosConfig = axios.create({
  baseURL: process.env.REACT_APP_WS_URL,
  headers: {
    Accept: 'application/json',
  },
});

const useWebService = () => {
  const dispatch = useDispatch();

  // Request interceptor
  axiosConfig.interceptors.request.use(
    (config) => {
      dispatch(RequestActions.setStatusRequest(true));

      if (['GET', 'get'].find((type) => type === config.method)) {
        config.params = {
          ...config.params,
          apikey: process.env.REACT_APP_WS_KEY,
        };
      } else {
        config.data.apikey = process.env.REACT_APP_WS_KEY;
      }

      return config;
    },
    (error) => {
      dispatch(RequestActions.setStatusRequest(false));
      return Promise.reject(error);
    }
  );

  // Response interceptor
  axiosConfig.interceptors.response.use(
    (response) => {
      dispatch(RequestActions.setStatusRequest(false));

      return response;
    },
    (error) => {
      dispatch(RequestActions.setStatusRequest(false));
      return Promise.reject(error);
    }
  );

  return axiosConfig;
};

export default useWebService;
