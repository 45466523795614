/* eslint-disable camelcase */
import {
  useCallback,
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import api from '~/services/api';
import { Creators as LodgingIdCoverActions } from '~/store/ducks/lodgingCoverId';
import { Creators as LodgingTotalImages } from '~/store/ducks/lodgingTotalImages';

import Card from './Card';
import PositionForm from './PositionForm';
import { Container } from './styles';

const DraggableBoard = (props, ref) => {
  const { token } = useSelector((state) => state.auth);
  const formRef = useRef();

  // Lodging
  const params = useParams();

  const dispatch = useDispatch();

  const [images, setImages] = useState({
    data: [],
    total: '0',
  });

  const getImages = useCallback(async () => {
    const { id } = params;
    const { data: lodging } = await api.get(`/lodgings/${id}`);
    const { data } = await api.get(`/lodgings/${id}/images?orderBy=order ASC`);

    const total = data.length;

    dispatch(LodgingTotalImages.setLodgingTotalImages(total));
    setImages({ data, total });
    dispatch(LodgingIdCoverActions.setLodgingCoverId(lodging.cover_id));
  }, [params, dispatch]);

  useEffect(() => {
    getImages();
  }, [getImages]);

  useImperativeHandle(ref, () => ({
    getImages,
  }));

  return (
    <Container>
      {images.data.map(({ id, path_url, ...restImage }) => (
        <Card
          key={id}
          positionFormRef={formRef}
          onDelete={getImages}
          data={{ id, path_url: `${path_url}?token=${token}`, ...restImage }}
        />
      ))}

      <PositionForm
        ref={formRef}
        onClose={(refresh) => {
          if (refresh) getImages();
        }}
      />
    </Container>
  );
};

export default forwardRef(DraggableBoard);
