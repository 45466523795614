import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  height: 175px;
  width: 100%;

  border-radius: 16px;
  /* overflow: hidden; */

  background-color: #eeeeee;
  box-shadow: 0 4px 8px rgba(154, 160, 185, 0.05),
    0 7px 20px rgba(166, 173, 201, 0.2);

  ${({ isPortrait }) =>
    isPortrait
      ? css`
          height: 380px;
          grid-row: span 2;
        `
      : css`
          height: 175px;
        `}

  ${({ imageUrl }) =>
    imageUrl &&
    css`
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-image: ${`url(${imageUrl})`};
    `}
`;

export const Order = styled.div``;
