import PropTypes from 'prop-types';
// import { MdEdit } from 'react-icons/md';

import CircleButton from '~/components/CircleButton';

import CoverButton from './CoverButton';
import PublicButton from './PublicButton';
import { Container } from './styles';

const Controls = ({ data, positionFormRef }) => {
  const { id } = data;

  return (
    <Container>
      <CoverButton imageId={id} />
      <PublicButton data={data} />
      <CircleButton
        tooltip="Mudar Ordem"
        icon={<span>{data.order}</span>}
        onClick={() => positionFormRef.current?.setData(data)}
      />
    </Container>
  );
};

Controls.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    order: PropTypes.number.isRequired,
    // portrait: PropTypes.bool.isRequired,
  }).isRequired,
  positionFormRef: PropTypes.objectOf().isRequired,
};

export default Controls;
