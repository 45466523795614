import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import Select from '~/components/Material/Select';
import api from '~/services/api';

const SelectTypeLodging = () => {
  const [lodgingTypes, setLodgingTypes] = useState();

  useEffect(() => {
    const getTeams = async () => {
      try {
        const {
          data: { data, total },
        } = await api.get(`/type-lodgings`, { params: { page_size: 9999 } });

        if (Number(total) > 0)
          return setLodgingTypes(
            data.map(({ id, description }) => ({
              value: id,
              label: description,
            }))
          );

        return setLodgingTypes([]);
      } catch (error) {
        toast.error('Erro ao buscar tipos de hospedagens...');

        return setLodgingTypes([]);
      }
    };

    getTeams();
  }, []);

  return (
    <Select
      label="Tipo da hospedagem"
      name="type_lodging_id"
      items={lodgingTypes}
      isSearchable
    />
  );
};

export default SelectTypeLodging;
