import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  height: 100%;

  margin-right: 24px;

  svg,
  img {
    height: 48px;

    .a {
      fill: #3a3a3c;
    }
    .b {
      fill: ${({ theme }) => theme.secondary.normal};
    }
  }
`;
