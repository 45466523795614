// import { ReactComponent as LogoSVG } from '~/assets/svg/logo.svg';
import LogoImg from '~/assets/img/novajornada-logo.png';

import { Container } from './styles';

const Logo = () => {
  return (
    <Container>
      {/* <LogoSVG /> */}
      <img src={LogoImg} alt="Nova Jornada Logo" />
    </Container>
  );
};

export default Logo;
