import PropTypes from 'prop-types';

import Controls from './Controls';
import DeleteButton from './DeleteButton';
import { Container } from './styles';

const Card = ({ onDelete, data, positionFormRef }) => {
  const { id, path_url } = data;

  return (
    <Container imageUrl={path_url}>
      <Controls data={data} positionFormRef={positionFormRef} />
      <DeleteButton imageId={id} onDelete={onDelete} />
    </Container>
  );
};

Card.propTypes = {
  onDelete: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    path_url: PropTypes.string.isRequired,
    order: PropTypes.number.isRequired,
    // portrait: PropTypes.bool.isRequired,
  }).isRequired,
  positionFormRef: PropTypes.objectOf().isRequired,
};

export default Card;
