/* eslint-disable no-await-in-loop */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { useRef, useCallback } from 'react';

import PropTypes from 'prop-types';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import Button from '~/components/Button';
import { useImageReader } from '~/hooks/useImageReader';
import api from '~/services/api';
import { Creators as LodgingTotalImages } from '~/store/ducks/lodgingTotalImages';

import { Container, Input } from './styles';

const ImportFile = ({ onChange }) => {
  // Lodging
  const { id } = useParams();
  const inputRef = useRef(null);
  const { loadImage } = useImageReader();
  const lodgingTotalImages = useSelector((state) => state.lodgingTotalImages);
  const dispatch = useDispatch();

  const handleChange = useCallback(
    async (event) => {
      const files = [...event.target.files];

      for (const key in files) {
        const currentFile = files[key];
        const current = Number(key) + 1;
        const order = Number(lodgingTotalImages) + current;

        const { name, isPortrait, file } = await loadImage(currentFile);
        const formData = new FormData();
        formData.append('name', name);
        formData.append('portrait', isPortrait);
        formData.append('order', order);
        formData.append('image', file);

        // Faz o upload da imagem
        try {
          const res = await api.post(`/lodgings/${id}/images`, formData);

          if (res.status === 201) {
            dispatch(LodgingTotalImages.setLodgingTotalImages(order));
          }
        } catch (error) {
          // error
        }
      }

      onChange();
    },
    [id, api, loadImage, lodgingTotalImages, onChange]
  );

  const handleClickOpenFinder = () => inputRef.current.click();

  return (
    <Container>
      <Button
        label="Importar"
        iconSize={24}
        icon={<AiOutlineCloudUpload />}
        onClick={handleClickOpenFinder}
      />
      <Input
        ref={inputRef}
        type="file"
        multiple="multiple"
        accept="image/*"
        onChange={handleChange}
      />
    </Container>
  );
};

ImportFile.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default ImportFile;
