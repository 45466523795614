import { forwardRef, useImperativeHandle, useState, useRef } from 'react';

import { toast } from 'react-toastify';

import Input from '~/components/Material/Input';
import SideForm from '~/components/SideForm';
import FixedControl from '~/components/SideForm/FixedControl';
import Section from '~/components/SideForm/Section';
import Title from '~/components/SideForm/Title';
import api from '~/services/api';

import { Content } from './styles';

const PositionForm = ({ ...rest }, ref) => {
  const formRef = useRef();
  const buttonRef = useRef();
  const [image, setImage] = useState({});

  useImperativeHandle(
    ref,
    () => ({
      setData: (value) => {
        setImage(value);
      },
    }),
    []
  );

  const closeForm = (shouldReload) => {
    formRef.current.reset();
    formRef.current.setErrors({});
    setImage({});
    rest.onClose(shouldReload);
  };

  const handleSubmit = async (formData) => {
    try {
      buttonRef.current.start();
      await api.put(`/lodgings/${image.lodging_id}/images/${image.id}`, {
        ...image,
        order: formData.order,
      });

      buttonRef.current.finish({
        type: 'success',
        callback: () => closeForm(true),
      });
    } catch (error) {
      toast.error(`Erro ao trocar ordem...`);
      buttonRef.current.finish({
        type: 'error',
        autoReset: true,
        timeReset: 2,
      });
    }
  };

  const handleOnClose = () => closeForm(false);

  return (
    <SideForm
      formRef={formRef}
      data={image}
      onClose={handleOnClose}
      onSubmit={handleSubmit}
    >
      <Content>
        <Title label="Editar Posição" />
        <Section>
          <Input label="Posição" name="order" type="number" />
        </Section>

        <FixedControl buttonRef={buttonRef} buttonLabel="Salvar" />
      </Content>
    </SideForm>
  );
};

export default forwardRef(PositionForm);
